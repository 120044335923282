import { CalibracaoService } from '@common/services';
import { TipoColunaConst } from '@const/TipoColunaConst';
export const MetodosConfirmacao = {
  methods: {
    confirmacaoLimparSMC: function () {
      this.confirmar(
        this.$t('geral.titulos.atencao'),
        this.$t('modulos.calibracao.valores.alertas.confirmar_limpar_smc')
      ).then(() => {
        this.limparColuna(TipoColunaConst.SMC);
      });
    },
    confirmacaoLimparSMP: function () {
      this.confirmar(
        this.$t('geral.titulos.atencao'),
        this.$t('modulos.calibracao.valores.alertas.confirmar_limpar_smp')
      ).then(() => {
        this.limparColuna(TipoColunaConst.SMP);
      });
    },
    confirmarLimparInstrumentoPadrao: function () {
      this.confirmar(
        this.$t(
          'modulos.calibracao.valores.titulos.confirmar_limpar_instrumento_padrao'
        ),
        this.$t(
          'modulos.calibracao.valores.alertas.confirmar_limpar_instrumento_padrao'
        )
      ).then(() => {
        this.$store.dispatch(
          'Layout/iniciarCarregamento',
          'Redefinindo instrumentos padrões'
        );
        CalibracaoService.limparInstrumentoPadrao(
          this.calibracaoId,
          this.tabelaSelecionada.id
        )
          .then(async () => {
            this.toastSucesso(
              this.$t(
                `modulos.calibracao.valores.alertas.limpar_instrumento_sucesso`
              )
            );
            this.recarregarTabela();
          })
          .catch(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      });
    },
    confirmacaoEditarInstrumentoPadrao: function () {
      if (!this.podeEditar) return;
      this.confirmar(
        this.$t(
          'modulos.calibracao.valores.titulos.confirmar_instrumento_padrao'
        ),
        this.$t(
          'modulos.calibracao.valores.alertas.confirmar_instrumento_padrao'
        )
      ).then(() => {
        this.instrumentoSelecionado = null;
        this.$refs.modalInstrumento.abrirModal();
      });
    },
    confirmacaoIrProximaTabela: function () {
      let indiceAtual = this.listaValores.indexOf(
        this.listaValores.find((el) => el.tabelaId == this.tabelaSelecionada.id)
      );
      if (this.listaValores.length - 1 == indiceAtual) {
        this.confirmarFinalizarCalibracao();
        return;
      }

      this.confirmar(
        this.$t('geral.titulos.atencao'),
        this.$t(`modulos.calibracao.valores.alertas.confirmar_proxima_tabela`)
      ).then(() => {
        this.tabelaSelecionada.id = this.listaValores[indiceAtual + 1].tabelaId;
      });
    },

    confirmacaoSalvar: function () {
      this.confirmar(
        this.$t('geral.titulos.atencao'),
        this.$t(
          `modulos.calibracao.valores.alertas.confirmar_salvar`
        )
      ).then(() => {
        this.$store.dispatch('Layout/iniciarCarregamento', 'Salvando tabelas');
        this.salvarMultiplasTabelas();
      });
    },

    confirmarFinalizarCalibracao: function () {
      this.confirmar(
        this.$t('geral.titulos.atencao'),
        this.$t('modulos.calibracao.valores.alertas.confirmar_finalizar'),
        true,
        true,
        this.$t('geral.botoes.sim'),
        this.$t('geral.botoes.nao'),
        {
          type: 'checkbox',
          valor: 1,
          texto: this.$t('modulos.calibracao.valores.alertas.checkbox_modal'),
        }
      ).then(async (res) => {
        const linhasSemValor = [];
        this.listaValores?.forEach(tabela => {
          tabela.linhas?.forEach(linha => {
            linha.valores?.forEach(valor => {
              if (valor?.tipoColuna) {
                const buscarSeLinhaJaFoiAdicionado = linhasSemValor.find((linhasSemValor) => {
                  return linhasSemValor.pontoCalibracao.id === linha.pontoCalibracao.id
                })
                if (!buscarSeLinhaJaFoiAdicionado) {
                  const tabelaPertencente = this.tabelasParaLinhasErro.find((tabelaParaLinhaErro) => {
                    return tabelaParaLinhaErro.tabela.id === tabela.tabelaId
                  })
                  if (!valor.valor)
                    linhasSemValor.push(
                      {
                        pontoCalibracao: linha.pontoCalibracao,
                        tabelaId: tabela.tabelaId,
                        tabela: `${tabelaPertencente?.procedimentoEletronico.nome} - ${tabelaPertencente?.tabela.nome} - ${tabelaPertencente?.amplitudeMinima} a ${tabelaPertencente?.amplitudeMaxima} ${tabelaPertencente?.unidadeMedida.nome}`

                      })
                }
              }

            })
          })
        });
        if (linhasSemValor.some(item => item)) {
          this.$refs['modal-linhas-erros'].abrirModal(linhasSemValor, 'Salvar')
          return
        }

        const linhasComErros = [];
        this.listaValores?.forEach(tabela => {
          tabela.linhas?.forEach(linha => {
            linha.resultados?.forEach(resultado => {
              if (resultado.valor === '') {

                const buscarSeLinhaJaFoiAdicionado = linhasComErros.find((linhaComErro) => {
                  return linhaComErro.pontoCalibracao.id === linha.pontoCalibracao.id
                })

                if (!buscarSeLinhaJaFoiAdicionado) {
                  const tabelaPertencente = this.tabelasParaLinhasErro.find((tabelaParaLinhaErro) => {
                    return tabelaParaLinhaErro.tabela.id === tabela.tabelaId
                  })
                  linhasComErros.push(
                    {
                      pontoCalibracao: linha.pontoCalibracao,
                      tabelaId: tabela.tabelaId,
                      tabela: `${tabelaPertencente?.procedimentoEletronico.nome} - ${tabelaPertencente?.tabela.nome} - ${tabelaPertencente?.amplitudeMinima} a ${tabelaPertencente?.amplitudeMaxima} ${tabelaPertencente?.unidadeMedida.nome}`
                    })
                }

              }
            })
          })
        })
        if (linhasComErros.some(item => item)) {
          this.$refs['modal-linhas-erros'].abrirModal(linhasComErros, "Finalizar")
          return
        }

        this.$store.dispatch('Layout/iniciarCarregamento');
        await this.salvarMultiplasTabelas();
        CalibracaoService.finalizarCalibracao(this.calibracaoId)
          .then(() => {
            this.toastSucesso(
              this.$t(`modulos.calibracao.valores.finalizado_sucesso`)
            );
            if (res.value) {
              this.$router.push({
                name: 'calibracao-interna-certificado',
                params: { id: this.calibracaoId },
                query: {
                  retorno: 'certificado-interno',
                },
              });
              return;
            }
            this.$router.push({ name: 'operacao' });
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      });
    },
  },
};
