import moment from 'moment';
import _ from 'lodash';
import { DropdownModel } from '../geral/DropdownModel';
export class CalibracaoInternaModel {
  constructor({
    id,
    instrumento,
    ordemServicoId,
    instrumentoId,
    instrumentoCodigo,
    dataCalibracao = moment(),
    tipoCalibracaoId,
    localExecucaoId,
    usuarioTecnicoExecutante,
    ultimaCalibracaoId = null,
    registroFinalizado = false,
    temperaturaInicio = 0,
    temperaturaMeio = 0,
    temperaturaFim = 0,
    temperaturaVariacao = 0,
    umidadeInicio = 0,
    umidadeMeio = 0,
    umidadeFim = 0,
    umidadeVariacao = 0,
    pressaoInicio = 0,
    pressaoMeio = 0,
    pressaoFim = 0,
    pressaoVariacao = 0,
    observacao,
    codigoCertificado,
    flagUtilizaLeitorOCR = false,
    situacaoCertificado = 'EmAndamento',
  }) {
    this.id = id;
    this.instrumento = instrumento;
    this.instrumentoId = instrumentoId;
    this.instrumentoCodigo = instrumentoCodigo;
    if (instrumentoId && instrumentoCodigo)
      this.instrumento = new DropdownModel({ id: instrumentoId, codigo: instrumentoCodigo }, 'codigo');
    this.ultimaCalibracaoId = ultimaCalibracaoId;
    this.ordemServicoId = ordemServicoId;
    this.dataCalibracao = dataCalibracao;
    this.tipoCalibracaoId = tipoCalibracaoId;
    this.localExecucaoId = localExecucaoId;
    this.usuarioTecnicoExecutanteId = usuarioTecnicoExecutante ? new DropdownModel(usuarioTecnicoExecutante) : null;
    this.registroFinalizado = registroFinalizado;
    this.temperaturaInicio = temperaturaInicio;
    this.temperaturaMeio = temperaturaMeio;
    this.temperaturaFim = temperaturaFim;
    this.temperaturaVariacao = temperaturaVariacao;
    this.umidadeInicio = umidadeInicio;
    this.umidadeMeio = umidadeMeio;
    this.umidadeFim = umidadeFim;
    this.umidadeVariacao = umidadeVariacao;
    this.pressaoInicio = pressaoInicio;
    this.pressaoMeio = pressaoMeio;
    this.pressaoFim = pressaoFim;
    this.pressaoVariacao = pressaoVariacao;
    this.observacao = observacao;
    this.codigoCertificado = codigoCertificado;
    this.flagUtilizaLeitorOCR = flagUtilizaLeitorOCR;
    this.situacaoCertificado = situacaoCertificado;
    this.podeCalibrar = situacaoCertificado == 'EmAndamento'
    this.flagInterna = true
  }

  get request() {
    let retorno = _.cloneDeep(this);
    retorno.instrumentoId = retorno.instrumento?.value;
    retorno.usuarioTecnicoExecutanteId =
      retorno.usuarioTecnicoExecutanteId?.value;

    retorno.usuarioSignatarioId = retorno.usuarioSignatarioId?.value;

    return retorno;
  }
}
