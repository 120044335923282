<template>
  <div>
    <div class="d-flex justify-space-between mb-8">
      <div class="titulo-pagina">
        <div>
          {{ tituloPagina }}
        </div>
        <div v-if="id">
          <v-chip class="mr-1" small> Instrumento: {{ badges.codigo }} </v-chip>
          <v-chip class="mr-1" small> Tipo: {{ badges.tipo }} </v-chip>
        </div>
      </div>
      <!-- Tomar cuidado com indice-->
      <input-select
        v-if="indiceAba == 2"
        v-model="tabelaSelecionada.id"
        class="w-35"
        :label="$t('modulos.certificado_interno.formulario.abas.tabelas_calibracao')"
        :placeholder="$t('geral.inputs.selecione')"
        :options="opcoesTabelas"
        em-filtro
        :clearable="false"
      />
    </div>
    <v-tabs
      v-model="indiceAba"
      class="abas-padrao"
      background-color="primary"
      color="primary"
      @change="salvarAoMudar"
    >
      <v-tab>
        {{ $t('modulos.certificado_interno.formulario.abas.dados') }}
      </v-tab>
      <v-tab>
        {{ $t('modulos.certificado_interno.formulario.abas.tabelas_calibracao') }}
      </v-tab>
      <v-tab>
        {{ $t('modulos.certificado_interno.formulario.abas.valores') }}
      </v-tab>
      <v-tab>
        {{ $t('modulos.certificado_interno.formulario.abas.anexos') }}
      </v-tab>
      <v-tab>
        {{ $t('modulos.certificado_interno.formulario.abas.requisitos') }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="indiceAbaAtual">
      <v-tab-item>
        <dados
          ref="dados"
          :form="calibracao"
          :instrumento-id="calibracao.instrumento?.value"
          :area-acreditacao-id="areaAcreditacaoId"
          :pode-calibrar="calibracao.podeCalibrar"
          :tipoinstrumento-id="instrumento?.tipoInstrumento?.value"
          @buscar-calibracao="continuarDados"
        />
      </v-tab-item>
      <v-tab-item>
        <tabela
          ref="tabelas"
          :calibracao-id="calibracao.id"
          :tabelas="tabelas"
          :pode-calibrar="calibracao.podeCalibrar"
          :faixas="faixas"
          :instrumento-padrao="instrumento.flagPadrao"
          @buscar-tabelas="continuarTabelas"
          @voltar-aba="voltarAba"
        />
      </v-tab-item>
      <v-tab-item eager>
        <valores
          ref="valores"
          :ultima-calibracao-id="calibracao.ultimaCalibracaoId"
          :calibracao-id="calibracao.id"
          :tabela-selecionada="tabelaSelecionada"
          :instrumento-id="calibracao.instrumentoId"
          :utiliza-ocr="false"
          :pode-calibrar="calibracao.podeCalibrar"
          :tabelas-para-linhas-erro="tabelas"
          @proxima-tabela="editarProximaTabela"
        />
      </v-tab-item>
      <v-tab-item>
        <anexos
          :calibracao-id="calibracao.id"
          :pode-calibrar="calibracao.podeCalibrar"
        />
      </v-tab-item>

      <v-tab-item>
        <requisitos :requisitos-cliente="dadosRequisitosClienteFormatado" />
      </v-tab-item>
    </v-tabs-items>
    <modal-requisitos
      ref="modalRequisitos"
      :requisitos-cliente="dadosRequisitosClienteFormatado"
    />
  </div>
</template>
<script>
import {
  CalibracaoService,
  ParticipanteService,
  InstrumentoService,
} from '@common/services';

import { CalibracaoInternaModel } from '@common/models/cadastros/CalibracaoInternaModel';
import { InstrumentoModel } from '@common/models/cadastros/InstrumentoModel';
import Requisitos from './components/Requisitos';
import Dados from './components/Dados';
import Tabela from './components/Tabela';
import Valores from './components/Valores';
import Anexos from './components/Anexos';
import ModalRequisitos from './components/modais/RequisitoModal';
import { DropdownModel } from '@/common/models/geral/DropdownModel';
import helpers from '@/common/utils/helpers';
import { mapGetters } from 'vuex';

export default {
  components: {
    Dados,
    ModalRequisitos,
    Requisitos,
    Tabela,
    Valores,
    Anexos,
  },
  props: ['id', 'instrumentoId', 'instrumentoAnaliseTecnicaId', 'calibracaoId'],
  data() {
    return {
      indiceAba: 0,
      indiceAbaAtual: 0,
      abasLiberadas: {
        tabelas: false,
        valores: false,
      },
      calibracao: new CalibracaoInternaModel({}),
      areaAcreditacaoId: null,
      tabelas: [],
      tabelaSelecionada: {
        id: null,
      },
      dadosRequisitosClienteFormatado: [],
      watchs: {
        localExecucao: null,
        dadosCalibracao: null,
      },
      dadosAlterados: false,
      instrumento: new InstrumentoModel({}),
      participanteParaBuscarRequisitosId: null,
    };
  },
  computed: {
    ...mapGetters('Autenticacao', [
      'participanteLogadoConta',
      'participanteSelecionado',
    ]),
    tituloPagina: function () {
      if (this.id && this.$route.name != 'calibracao-interna-visualizar') {
        return this.$t('modulos.certificado_interno.titulos.editar');
      }
      if (
        this.$route.name != 'calibracao-interna-visualizar' &&
        this.calibracao.podeCalibrar
      )
        return this.$t('modulos.certificado_interno.titulos.novo');

      return this.$t('modulos.certificado_interno.titulos.visualizar');
    },
    faixas: function () {
      return this.instrumento.instrumentoFaixa.map((el) => {
        return {
          text: el.descricao,
          value: el.id,
        };
      });
    },
    badges: function () {
      return {
        codigo: this.instrumento?.codigoTag,
        tipo: this.instrumento?.tipoInstrumento?.text,
      };
    },
    opcoesTabelas: function () {
      return this.tabelas.map((el) => {
        return {
          value: el.tabela.id,
          text: `${el.procedimentoEletronico.nome} - ${el.tabela.nome}${
            el.unidadeMedida
              ? ` - (${helpers.formatarNumeroComPrecisao(
                  el.amplitudeMinima
                )} a ${helpers.formatarNumeroComPrecisao(el.amplitudeMaxima)}) ${
                  el.unidadeMedida.nome
                }`
              : ''
          }`,
        };
      });
    },
  },
  watch: {
    id: async function () {
      if (this.id) await this.buscarCalibracao(true);
    },
    indiceAbaAtual: function () {
      if (this.indiceAbaAtual == 2) {
        this.$refs.valores.inicializarTela();
      }
    },
    'calibracao.instrumento': async function () {
      await this.buscarInstrumento();
    },
  },
  async mounted() {

    this.calibracao.usuarioTecnicoExecutanteId = new DropdownModel(
      this.$store.getters['Autenticacao/usuario']
    );
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.certificado_interno.titulos.pagina')
    );
    if (this.id) {
      await this.buscarCalibracao();
      // helpers.redirecionarSemPermissao('CertificadoInterno', 'Deletar');
    }
    // helpers.redirecionarSemPermissao('CertificadoInterno', 'Inserir');


    if (!this.watchs.dadosCalibracao) {
      this.watchs.dadosCalibracao = this.$watch(
        'calibracao',
        () => {
          this.dadosAlterados = true;
        },
        {
          deep: true,
        }
      );
    }

    await this.buscarRequisitos();
  },

  methods: {
    voltarAba: function () {
      this.indiceAba = this.indiceAbaAtual;
    },
    salvarAoMudar() {
      if (this.$route.name == 'certificado-interno-visualizar') {
        this.indiceAbaAtual = this.indiceAba;
        return;
      }
      if(!this.id) this.dadosAlterados = true;

      console.log(this.dadosAlterados)
      let alterado = true;
      switch (this.indiceAbaAtual) {
        case 0:
          alterado = this.dadosAlterados;
          break;
        case 1:
          alterado = this.$refs.tabelas.foiAlterado();
          break;
        case 2:
          alterado = this.$refs.valores.foiAlterado();
          break;
        default:
          alterado = false;
          break;
      }

      if (alterado) {
        let valido = false;
        let funcao = null;
        let ctx = this;
        switch (this.indiceAbaAtual) {
          case 0:
            valido = this.$refs.dados.validar();
            funcao = this.$refs.dados.salvar;
            break;
          case 1:
            valido = this.$refs.tabelas.validar();
            funcao = this.$refs.tabelas.salvar;
            break;
          case 2:
            valido = true;
            funcao = async function (proximaAba) {
              ctx.$store.dispatch(
                'Layout/iniciarCarregamento',
                'Salvando tabelas'
              );
              await ctx.$refs.valores.salvarMultiplasTabelas();
              ctx.indiceAbaAtual = proximaAba;
            };
            break;
          default:
            valido = true;
            break;
        }
        if (!valido) {

          this.$nextTick(() => {
            this.indiceAba = this.indiceAbaAtual;
          });
          return;
        }
        if (!funcao) {

          this.indiceAbaAtual = this.indiceAba;
          return;
        }
        funcao(this.indiceAba);
      } else {

        this.indiceAbaAtual = this.indiceAba;
      }
    },
    dadosRequisitos: function (dados) {
      this.dadosRequisitosClienteFormatado = dados;
    },
    mudarAba: function (indice) {
      this.indiceAba = indice;
    },
    continuarDados: async function (proximaAba) {
      await this.buscarCalibracao();
      this.$store.dispatch('Layout/iniciarCarregamento');
      if (this.$refs.tabelas) this.$refs.tabelas.buscarTabelasProcedimento();
      this.indiceAbaAtual = proximaAba;
      this.indiceAba = proximaAba;
    },
    buscarCalibracao: async function (alterarAba = false) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      this.abasLiberadas = {
        tabelas: false,
        valores: false,
      };
      if (this.watchs.dadosCalibracao != null) this.watchs.dadosCalibracao();
      this.dadosAlterados = false;
      await CalibracaoService.buscarPorId(this.id)
        .then(async (res) => {
          this.calibracao = new CalibracaoInternaModel(res.data);

          this.abasLiberadas.tabelas = true;
          this.buscarTabelas();

          this.definirTecnicoExecutante();

          await this.buscarInstrumento();
          if (alterarAba) {
            this.indiceAbaAtual = 1;
            this.indiceAba = 1;
          }
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
          this.watchs.dadosCalibracao = this.$watch(
            'calibracao',
            () => {
              this.dadosAlterados = true;
            },
            {
              deep: true,
            }
          );
        });
    },
    buscarInstrumento: async function () {
      this.$store.dispatch(
        'Layout/iniciarCarregamento',
        'Buscando instrumento'
      );
      await InstrumentoService.buscar(this.calibracao.instrumento?.value)
        .then((res) => {
          this.instrumento = new InstrumentoModel(res.data);
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },

    continuarTabelas: async function (proximaAba) {
      await this.buscarTabelas();
      if (this.$refs.valores) this.$refs.valores.buscarListaTabelasCalibracao();
      this.indiceAbaAtual = proximaAba;
      this.indiceAba = proximaAba;
    },
    buscarTabelas: async function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      this.abasLiberadas.valores = false;
      await CalibracaoService.buscarTabelas(this.calibracao.id)
        .then((res) => {
          this.tabelas = res.data.map((el) => {
            el.id = el.identificadorTabela;
            return el;
          });

          if (this.tabelas.length > 0) {
            this.abasLiberadas.valores = true;
            this.tabelaSelecionada.id = this.tabelas[0].tabela.id;
          } else {
            this.tabelaSelecionada.id = null;
          }
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },

    definirTecnicoExecutante() {
      this.calibracao.usuarioTecnicoExecutanteId = new DropdownModel(
        this.$store.getters['Autenticacao/usuario']
      );
    },

    editarProximaTabela: function () {
      let indice =
        this.tabelas.indexOf(
          this.tabelas.find((el) => el.tabela.id == this.tabelaSelecionada.id)
        ) + 1;
      if (indice == this.tabelas.length) return;

      this.tabelaSelecionada.id = this.tabelas[indice].tabela.id;
    },
    buscarRequisitos: async function () {
      let participanteClienteId = null;

      if (this.instrumento.participanteCliente?.value)
        participanteClienteId = this.instrumento.participanteCliente?.value;

      if (!this.participanteLogadoConta)
        this.participanteClienteId = this.participanteSelecionado;

      if (!participanteClienteId) return;

      await ParticipanteService.buscaRequisitosParticipante(
        participanteClienteId,
        'tipoRequisito=3'
      ).then((res) => {
        if (res?.data.length > 0) {
          this.dadosRequisitos(res?.data);
          if (res.data[0].requisitos.length > 0)
            this.$refs.modalRequisitos.abrirModal();
        }
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    if (
      to.name !== 'login' &&
      this.$refs.valores !== undefined &&
      this.$refs.valores.foiAlterado() &&
      this.$route.name != 'calibracao-interna-visualizar' &&
      this.calibracao.podeCalibrar
    ) {
      this.confirmar(
        this.$t('geral.titulos.atencao'),
        this.$t(
          'modulos.certificado_interno.valores.alertas.confirmar_sair_sem_salvar_calibracao'
        )
      )
        .then(() => {
          next();
        })
        .catch(() => {
          next(false);
        });
    } else {
      next();
    }
  },
};
</script>
