import { CalibracaoService } from '@common/services';
export const MetodosSalvar = {
  methods: {
    salvarUnicaTabela: async function (tabela = null) {
      return await new Promise((resolve) => {
        CalibracaoService.salvarValores(
          this.calibracaoId,
          this.tabelaSelecionada.id,
          tabela ?? this.tabelaValoresAtual
        )
          .then(() => {
            this.toastSucesso(
              this.$t(`modulos.calibracao.valores.cadastro_sucesso`)
            );
            resolve();
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      });
    },
    salvarMultiplasTabelas: async function () {
      await CalibracaoService.salvarMultiplasTabelasValores(
        this.calibracaoId,
        this.listaValores
      )
        .then(() => {
          this.toastSucesso(
            this.$t(`modulos.calibracao.valores.cadastro_sucesso`)
          );
          this.alterado = false;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    salvarInstrumentosPadroes: function (tabela) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      this.salvarUnicaTabela(tabela).then(() => {
        this.$refs.modalInstrumento.fecharModal();
        this.recarregarTabela();
      });
    },
  },
};
