<template>
  <div>
    <div class="mt-3 d-flex align-center justify-space-between">
      <div />
      <input-select
        v-model="faixaSelecionada"
        class="w-35"
        :label="$t('modulos.calibracao.formulario.abas.faixas')"
        :placeholder="$t('geral.inputs.selecione')"
        :options="faixas"
        em-filtro
        :clearable="false"
      />
    </div>
    <v-alert
      v-if="instrumentoPadrao"
      type="warning"
      dense
      class="mt-2"
    >
      {{ $t('modulos.calibracao.tabela_calibracao.instrumento_padrao') }}
    </v-alert>
    <v-alert
      v-if="camposAdicionaisRestrito"
      type="warning"
      dense
      class="mt-2"
    >
      {{ $t('modulos.calibracao.tabela_calibracao.campo_adicional_restrito') }}
    </v-alert>
    <tabela-padrao
      v-if="tabelasSelecionadasFiltrada?.selecionados"
      v-model="tabelasSelecionadasFiltrada.selecionados"
      sem-acoes
      :dados="tabela.dados"
      :colunas="tabela.colunas"
      class="mt-2"
      :validacao-selecionar="podeSelecionar"
      sem-selecionar-todos
      :disabled="!podeEditar"
    >
      <template v-slot:item.procedimentoEletronico="{ item }">
        <div>
          {{ item.value.codigo }} - {{ item.value.nome }}
          <icone-padrao
            v-if="!item.item.allPontosHasEscopoFaixa"
            icone="$alertWarningtOutline"
            cor="red"
            :tooltip="$t('modulos.calibracao.formulario.alerta_escopo')"
          />
        </div>
      </template>
    </tabela-padrao>
    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao
        outlined
        color="secondary"
        class="mr-2"
        @click="cancelar"
      >
        <v-icon class="mr-2">
          $mdiCancel
        </v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
      <botao-padrao
        v-if="podeEditar"
        @click="confirmacaoSalvar"
      >
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
    </div>
  </div>
</template>
<script>
import ProcedimentoEletronicoService from '@common/services/cadastros/ProcedimentoEletronicoService';
import CalibracaoService from '@common/services/cadastros/CalibracaoService';
import _ from 'lodash';
import helpers from '@/common/utils/helpers';
export default {
  props: {
    calibracaoId: {},
    tabelas: { default: () => [] },
    faixas: {},
    podeCalibrar: {},
    instrumentoPadrao: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      faixaSelecionada: null,
      camposAdicionaisRestrito: false,
      watchTabelas: null,
      alterado: true,
      tabelaAlteradosClone: null,
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'procedimentoEletronico',
            text: this.$t('modulos.calibracao.tabela_calibracao.procedimento'),
            sortable: false,
            formatter: (valor) => {
              if (valor) return `${valor.codigo} - ${valor.nome}`;
            },
          },
          {
            value: 'nome',
            text: this.$t('modulos.calibracao.tabela_calibracao.tabela'),
            sortable: false,
          },
          {
            value: 'faixa',
            text: this.$t('modulos.calibracao.tabela_calibracao.faixa'),
            sortable: false,
            formatter: (valor, linha) => {
              if (linha && linha.unidadeMedida)
                return `(${helpers.formatarNumeroComPrecisao(
                  linha.amplitudeMinima
                )} a ${helpers.formatarNumeroComPrecisao(
                  linha.amplitudeMaxima
                )}) ${linha.unidadeMedida.nome}`;
            },
          },
          {
            value: 'pontosCalibracao',
            text: this.$t('modulos.calibracao.tabela_calibracao.pontos'),
            sortable: false,
            formatter: (valor) => {
              if (valor)
                return valor
                  .filter((el) => el.faixaId == this.faixaSelecionada)
                  .map((el) => helpers.formatarNumeroComPrecisao(el.ponto))
                  .join(' / ');
            },
          },
        ],
      },
    };
  },
  computed: {
    tooltipBotaoSalvar: function () {
      if (this.valido) return '';
      return this.$t('modulos.calibracao.tabela_calibracao.selecione_linha');
    },
    valido: function () {
      return this.tabela.selecionados.length > 0;
    },
    podeEditar: function () {
      return (
        this.$route.name != 'calibracao-interna-visualizar' && this.podeCalibrar
      );
    },
    tabelasSelecionadasFiltrada: function () {
      return this.tabela.selecionados.find(
        (el) => el.faixaId == this.faixaSelecionada
      );
    },
  },
  watch: {
    faixaSelecionada: function () {
      if (!this.faixaSelecionada) return;
      if (
        !this.tabela.selecionados.find(
          (el) => el.faixaId == this.faixaSelecionada
        )
      )
        this.tabela.selecionados.push({
          faixaId: _.cloneDeep(this.faixaSelecionada),
          selecionados: [],
        });
    },
    tabelas: {
      handler() {
        const chaveUnica = new Set();
        const novoArray = [];
        if (
          (!this.tabelas ? true : this.tabelas.length == 0) &&
          this.tabela.selecionados.length > 0
        )
          return;

        for (const faixa of this.tabelas) {
          for (const selecionado of faixa.faixasIds) {
            if (!chaveUnica.has(selecionado)) {
              chaveUnica.add(selecionado);
              novoArray.push(selecionado);
            }
          }
        }

        this.tabela.selecionados = novoArray.map((n) => {
          return {
            faixaId: n,
            selecionados: this.tabelas
              .filter((el) => el.faixasIds.find((x) => x == n))
              .map((el) => {
                return {
                  id: el.identificadorTabela,
                  procedimentoEletronico: el.procedimentoEletronico,
                };
              }),
          };
        });

        this.faixas.forEach((faixa) => {
          if (!this.tabela.selecionados.find((el) => el.faixaId == faixa.value))
            this.tabela.selecionados.push({
              faixaId: _.cloneDeep(faixa.value),
              selecionados: [],
            });
        });

        this.buscarTabelasProcedimento();
      },
      immediate: true,
    },
  },
  methods: {
    validar: function () {
      return this.tabela.selecionados.length > 0;
    },
    foiAlterado: function () {
      return this.alterado;
    },
    podeSelecionar: function (item) {
      const chaveUnica = new Set();
      const novoArray = [];

      for (const faixa of this.tabela.selecionados) {
        for (const selecionado of faixa.selecionados) {
          if (!chaveUnica.has(selecionado.id)) {
            chaveUnica.add(selecionado.id);
            novoArray.push(selecionado);
          }
        }
      }
      if (!item.allPontosHasEscopoFaixa || !item.isUsuarioLogadoExecutante)
        return false;

      if (novoArray.length == 0) return true;

      const selecionados = this.tabela.selecionados.find(
        (el) => el.faixaId == this.faixaSelecionada
      ).selecionados;
      if (this.instrumentoPadrao && selecionados.length > 0) {
        return item.id == selecionados[0].id;
      }

      return (
        item.procedimentoEletronico.id == novoArray[0].procedimentoEletronico.id
      );
    },
    buscarTabelasProcedimento: function () {
      if (this.watchTabelas) this.watchTabelas();
      let selecionados = [];
      ProcedimentoEletronicoService.buscarTabelasCalibracao(this.calibracaoId)
        .then((res) => {
          this.$store.dispatch('Layout/iniciarCarregamento');
          this.tabela.dados = res.data.tabelas.map((el) => {
            el.id = el.identificadoTabela;
            return el;
          });
          this.camposAdicionaisRestrito =
            res.data.hasRestrictiveCamposAdicionais;

          let tabelas = this.tabela.dados.filter(
            (el) => el.allPontosHasEscopoFaixa
          );
          if (this.tabelas.length == 0 && tabelas.length > 0) {
            let procedimentoId = tabelas[0].procedimentoEletronico.id;
            if (
              tabelas.every(
                (el) => el.procedimentoEletronico.id == procedimentoId
              )
            ) {
              selecionados = _.cloneDeep(tabelas);
            } else {
              let primeiroProcedimentoId = tabelas[0].procedimentoEletronico.id;
              selecionados = _.cloneDeep(
                tabelas.filter(
                  (el) => el.procedimentoEletronico.id == primeiroProcedimentoId
                )
              );
            }
          }

          this.watchTabelas = this.$watch(
            'tabela.selecionados',
            function (n, o) {
              if (
                !_.isEqual(this.tabelaAlteradosClone, o) &&
                this.tabelaAlteradosClone != null
              ) {
                this.alterado = true;
              }
              this.tabelaAlteradosClone = _.cloneDeep(this.tabela.selecionados);
            },
            { deep: true }
          );
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
          if (!this.faixaSelecionada && this.faixas.length) {
            if (selecionados.length > 0) {
              this.tabelaAlteradosClone = [];
              this.faixas.forEach((faixa) => {
                this.tabela.selecionados.find(
                  (el) => el.faixaId == faixa.value
                ).selecionados = this.instrumentoPadrao
                  ? [selecionados[0]]
                  : selecionados;
              });
            }
            this.faixaSelecionada = this.faixas[0].value;
          }
          this.tabelaAlteradosClone = _.cloneDeep(this.tabela.selecionados);
        });
    },
    confirmacaoSalvar: function () {
      if (this.id) {
        this.confirmarSalvar().then(() => {
          this.salvar();
        });
      } else this.salvar();
    },
    salvar: function (proximaAba = 1) {
      const chaveUnica = new Set();
      const novoArray = [];

      for (const faixa of this.tabela.selecionados) {
        for (const selecionado of faixa.selecionados) {
          if (!chaveUnica.has(selecionado.id)) {
            chaveUnica.add(selecionado.id);
            novoArray.push(selecionado);
          }
        }
      }

      const payload = novoArray.map((n) => {
        return {
          tabelaId: n.id,
          faixasIds: this.tabela.selecionados
            .filter((el) => el.selecionados.find((x) => x.id == n.id))
            .map((el) => el.faixaId),
        };
      });

      if (payload.length == 0) {
        this.$emit('buscar-tabelas', proximaAba);
        return;
      }
      this.eventoSalvar(payload, proximaAba);
    },
    eventoSalvar: function (payload, proximaAba) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      CalibracaoService.salvarTabelas(this.calibracaoId, payload)
        .then(() => {
          this.alterado = false;
          this.toastSucesso(
            this.$t(`modulos.calibracao.tabela_calibracao.cadastro_sucesso`)
          );
          this.$emit('buscar-tabelas', proximaAba);
        })
        .catch(() => {
          this.$emit('voltar-aba');
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.$router.push({
          name: this.$route.query?.retorno || 'operacao',
          params: { id: this.$route.query?.instrumentoRetorno || null },
        });
      });
    },
  },
};
</script>
