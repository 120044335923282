<template>
  <modal-padrao
    ref="modal"
    max-width="900px"
    :titulo="$t('modulos.calibracao.valores.titulos.depuracao_modal')"
    :mostra-botao-salvar="false"
  >
    <div>
      <v-simple-table>
        <tbody>
          <tr style="background: #f2f4f7">
            <td style="width: 250px">
              <div class="d-flex align-center">
                <botao-padrao
                  class="mr-2"
                  color="secondary"
                  text
                  @click="expandirTodos"
                >
                  <v-icon>{{expandidoTodos ? '$upArrow' : '$downArrow'}}</v-icon>
                </botao-padrao>
                <span>
                  {{ $t('modulos.calibracao.valores.titulos.variavel') }}
                </span>
              </div>
            </td>
            <td>{{ $t('modulos.calibracao.valores.titulos.valor') }}</td>
          </tr>
        </tbody>
        <tbody v-for="(log, i) in logs" :key="i">
          <tr>
            <td class="cabecalho" colspan="2">
              <div class="d-flex align-center">
                <botao-padrao
                  class="mr-2"
                  color="secondary"
                  text
                  @click="log.expandido = !log.expandido"
                >
                  <v-icon>{{log.expandido ? '$upArrow' : '$downArrow'}}</v-icon>
                </botao-padrao>
                <span>
                  {{ log.cabecalho }}
                </span>
              </div>
            </td>
          </tr>
          <template v-if="log.expandido">
            <tr
              v-for="(detalhe, j) in log.detalhes"
              :key="j"
              :class="detalhe.chave == 'variável não encontrada' ? 'erro' : ''"
            >
              <td style="width: 250px" class="pl-7">
                {{ detalhe.chave }}
              </td>
              <td>
                <vue-json-pretty :show-icon="true" :data="detalhe.valor" />
              </td>
            </tr>
          </template>
        </tbody>
      </v-simple-table>
    </div>
  </modal-padrao>
</template>
<script>
import CalibracaoService from '@common/services/cadastros/CalibracaoService';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
export default {
  components: {
    VueJsonPretty,
  },
  data() {
    return {
      logs: [],
    };
  },
  computed: {
    expandidoTodos : function(){
      return this.logs.every(el => el.expandido);
    }
  },
  methods: {
    abrirModal: function (calibracaoId, linha) {
      this.$store.dispatch('Layout/iniciarCarregamento');
      CalibracaoService.calcularLinha(calibracaoId, linha.id, linha, true)
        .then((res) => {
          this.logs = res.data.logs.map((el) => {
            el.expandido = false;
            el.detalhes = el.detalhes.map((detalhe) => {
              try {
                detalhe.valor = JSON.parse(detalhe.valor);
                detalhe.json = true;
              } catch (e) {
                detalhe.json = false;
              }
              return detalhe;
            });
            return el;
          });
          this.$refs.modal.abrirModal();
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    expandirTodos : async function(){
      let expandir = !this.logs.every(el => el.expandido);
      this.logs = this.logs.map(el => {
        el.expandido = expandir;
        return el
      })
    }
  },
};
</script>
<style>
td.cabecalho {
  border-bottom: 2px solid #e4e7ec;
}
tr:nth-child(even) {
  background: #f9fafb;
}
tr.erro {
  background: rgb(242, 190, 190);
}
tr.erro .vjs-value-string {
  color: #f00;
  font-weight: 700;
}
</style>
