import { CalibracaoService } from '@common/services';
import { CalibracaoTabelaModel } from '@common/models/cadastros/CalibracaoTabelaModel';
import { TipoColunaConst } from '@const/TipoColunaConst';
import _ from 'lodash';
export const MetodosBuscar = {
  methods: {
    buscarListaTabelasCalibracao: async function () {
      this.$store.dispatch(
        'Layout/iniciarCarregamento',
        this.$t('modulos.calibracao.carregamentos.carregando_tabela')
      );
      await CalibracaoService.buscarListaTabelasCalibracao(this.calibracaoId)
        .then((res) => {
          this.listaValores = res.data.map((el) => {
            return new CalibracaoTabelaModel(el, this.calibracaoId);
          });

          //INICIALIZAR LISTA DE LINHAS ALTERADAS
          this.listaValores.forEach((tabela) => {
            tabela.linhas.forEach((linha) => {
              this.linhasCalculadas.push({
                linhaId: linha.id,
                valores: linha.valores
                  .filter(
                    (el) =>
                      el.tipoColuna == TipoColunaConst.SMC ||
                      el.tipoColuna == TipoColunaConst.SMP
                  )
                  .map((el) => {
                    return {
                      tabelaColunaId: el.tabelaColunaId,
                      valor: _.cloneDeep(el.valor),
                    };
                  }),
              });
            });
          });

          setTimeout(() => {
            this.$refs.inputOrdem1[0].focus();
          }, 500);
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    buscarTabelaCalibracao: async function () {
      let tabela = null;
      await CalibracaoService.buscarTabelaCalibracao(
        this.calibracaoId,
        this.tabelaSelecionada.id
      )
        .then((res) => {
          tabela = new CalibracaoTabelaModel(
            { ...res.data, tabelaId: this.tabelaSelecionada.id },
            this.calibracaoId
          );
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
      return tabela;
    },
    recarregarTabela: async function () {
      this.$store.dispatch('Layout/iniciarCarregamento', 'Recarregando tabela');
      let tabela = await this.buscarTabelaCalibracao();
      this.chamarRedis();
      let indiceAtual = this.listaValores.indexOf(
        this.listaValores.find((el) => el.tabelaId == this.tabelaSelecionada.id)
      );
      this.listaValores[indiceAtual] = tabela;
      let tabelaSelecionada = this.tabelaSelecionada.id;
      this.tabelaSelecionada.id = null;
      setTimeout(() => {
        this.tabelaSelecionada.id = tabelaSelecionada;
      }, 500);
    },
    chamarRedis : function(){
      this.$store.dispatch(
        'Layout/iniciarCarregamento',
        'Carregando cache do calculo'
        );
      this.bloquearCampos = false;
      CalibracaoService.cache(this.calibracaoId)
      .catch(() => {
        this.bloquearCampos = true;
      })
      .finally(() => {
        this.$store.dispatch('Layout/terminarCarregamento');
      })
    },
  },
};
