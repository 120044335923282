<template>
  <div>
    <modal-padrao
      ref="modal"
      max-width="90%"
      :titulo="$t('componentes.ocr.modal')"
      :ok-desabilitado="!podeConfirmar"
      @cancelar="finalizarCompartilhamento"
      @click-fora="finalizarCompartilhamento"
      @ok="iniciarReconhecimento"
    >
      <div>
        <canvas id="canvasPreview" width="500" height="500" />
      </div>
    </modal-padrao>
  </div>
</template>
<script>

import Tesseract from 'tesseract.js';
export default {
  data: function () {
    return {
      canvas: null,
      ctx: null,
      estaCortando: false,
      pontoXInicial: 0,
      pontoXFinal: 0,
      pontoYInicial: 0,
      pontoYFinal: 0,
      video: null,
      stream: null,
      estaReconhecendo: false,
    };
  },
  computed: {
    podeConfirmar: function () {
      return !(
        this.pontoXInicial == 0 &&
        this.pontoXFinal == 0 &&
        this.pontoYInicial == 0 &&
        this.pontoYFinal == 0
      );
    },
  },
  methods: {
    iniciarCompartilhamento: function () {
      let contexto = this;
      navigator.mediaDevices
        .getDisplayMedia({ video: { mediaSource: 'window' } })
        .then((stream) => {
          if (this.stream != null) this.finalizarCompartilhamento();
          this.stream = stream;
          this.stream
            .getVideoTracks()[0]
            .addEventListener('ended', this.finalizarCompartilhamento);

          const videoElement = document.createElement('video');
          videoElement.srcObject = stream;
          contexto.video = videoElement;

          this.$refs.modal.abrirModal();
          this.$store.dispatch('Layout/iniciarCarregamento');
          setTimeout(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
            if (!this.canvas) {
              this.canvas = document.getElementById('canvasPreview');
              this.ctx = this.canvas.getContext('2d');
              this.canvas.addEventListener('mousedown', this.iniciarCorte);
              this.canvas.addEventListener('touchstart', this.iniciarCorte);
              this.canvas.addEventListener('mousemove', this.executarCorte);
              this.canvas.addEventListener('touchmove', this.executarCorte);
              this.canvas.addEventListener('mouseup', this.terminarCorte);
              this.canvas.addEventListener('touchend', this.terminarCorte);
            }

            contexto.canvas.width = videoElement.videoWidth;
            contexto.canvas.height = videoElement.videoHeight;

            contexto.ctx.drawImage(
              videoElement,
              0,
              0,
              contexto.canvas.width,
              contexto.canvas.height
            );
          }, 1000);

          videoElement.onloadedmetadata = () => {
            videoElement.play();
          };
        })
        .catch((error) => {
          console.error('Erro ao compartilhar a janela:', error);
        });
    },
    finalizarCompartilhamento: function () {
      this.stream.getTracks().forEach((track) => track.stop());
      this.pontoXInicial = 0;
      this.pontoXFinal = 0;
      this.pontoYInicial = 0;
      this.pontoYFinal = 0;
      this.estaReconhecendo = false;
      this.$emit('atualizar-status-ocr', false);
    },
    iniciarReconhecimento: function () {
      this.estaReconhecendo = true;
      this.$emit('atualizar-status-ocr', true);
      this.$refs.modal.fecharModal();
    },
    reconhecerTexto: async function (referencia, input) {
      if (!this.estaReconhecendo) return;

      referencia.iniciarCarregamento();
      const canvasElement = document.createElement('canvas');
      canvasElement.width = this.video.videoWidth;
      canvasElement.height = this.video.videoHeight;
      const context = canvasElement.getContext('2d');

      context.drawImage(
        this.video,
        0,
        0,
        canvasElement.width,
        canvasElement.height
      );

      const width = this.pontoXFinal - this.pontoXInicial;
      const height = this.pontoYFinal - this.pontoYInicial;

      const imagemCortada = context.getImageData(
        this.pontoXInicial,
        this.pontoYInicial,
        width,
        height
      );

      canvasElement.width = width;
      canvasElement.height = height;

      context.putImageData(imagemCortada, 0, 0);

      const imageDataURL = canvasElement.toDataURL('image/png');

      Tesseract.recognize(imageDataURL, 'eng').then(({ data: { text } }) => {

        referencia.terminarCarregamento();
        let novaStr = text.replace('.', ',');
        novaStr = novaStr.replace(/\./g, '');
        novaStr = novaStr.replace(',', '.');
        novaStr = novaStr.replace(/\n/g, '');
        novaStr = novaStr.replace(/^0+/, '');
        referencia.alterarValor(novaStr);
        input.valor = novaStr;

        this.$emit('texto-reconhecido', novaStr);
      });
    },
    //Metodos do corte
    iniciarCorte(e) {
      this.estaCortando = true;
      this.pontoXInicial =
        e.offsetX || e.touches[0].pageX - this.canvas.offsetLeft;
      this.pontoYInicial =
        e.offsetY || e.touches[0].pageY - this.canvas.offsetTop;
    },
    executarCorte(e) {
      if (!this.estaCortando) return;

      const pontoXAtual =
        e.offsetX || e.touches[0].pageX - this.canvas.offsetLeft;
      const pontoYAtual =
        e.offsetY || e.touches[0].pageY - this.canvas.offsetTop;

      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

      this.ctx.drawImage(
        this.video,
        0,
        0,
        this.canvas.width,
        this.canvas.height
      );

      const width = pontoXAtual - this.pontoXInicial;
      const height = pontoYAtual - this.pontoYInicial;
      this.ctx.strokeStyle = 'red';
      this.ctx.lineWidth = 2;
      this.ctx.strokeRect(
        this.pontoXInicial,
        this.pontoYInicial,
        width,
        height
      );
    },
    terminarCorte(e) {
      this.estaCortando = false;

      this.pontoXFinal =
        e.offsetX || e.changedTouches[0].pageX - this.canvas.offsetLeft;
      this.pontoYFinal =
        e.offsetY || e.changedTouches[0].pageY - this.canvas.offsetTop;
    },
  },
};
</script>
