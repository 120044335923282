<template>
  <modal-padrao
    ref="modal"
    max-width="1200px"
    :titulo="
      $t('modulos.calibracao.valores.titulos.confirmar_instrumento_padrao')
    "
    :ok-desabilitado="pontosSemInstrumento.length > 0"
    :persistente="persistente"
    :mostrar-botao-cancelar="!persistente"
    @ok="alterarInstrumento"
  >
    <div>
      <v-alert
        v-if="pontosVazios.length > 0"
        color="red"
        type="error"
      >
        Existem pontos de calibração sem instrumentos padrões, por favor associe
        algum instrumento para esses casos para continuar calibrando!
      </v-alert>
      <accordion-padrao
        nao-retrair
        :titulo="$t('modulos.calibracao.valores.modal.variaveis')"
      >
        <input-radio
          v-model="form.variavel"
          class-grupo="d-flex flex-column"
          :options="opcoesVariaveisComputadas"
        >
          <template #label="{ opcao }">
            <div
              class="d-flex"
              :class="opcao.pontosNaoAtribuidos.length ? 'red--text' : ''"
            >
              <span class="mr-2">{{ opcao.text }}</span>
              <icone-padrao
                v-if="opcao.pontosNaoAtribuidos.length"
                cor="red"
                icone="$warning"
                class="pl-2"
                :tooltip="`Os seguintes pontos (${opcao.pontosNaoAtribuidos
                  .map((el) => el.ponto.text)
                  .join(
                    ', '
                  )}) estão sem instrumento padrão atribuídos para essa variável`"
              />
            </div>
          </template>
        </input-radio>
      </accordion-padrao>
      <accordion-padrao
        nao-retrair
        :titulo="$t('modulos.calibracao.valores.modal.pontos')"
      >
        <div
          v-for="opcao in opcoesPontos"
          :key="opcao.value"
          class="d-flex"
        >
          <input-checkbox
            v-model="form.pontos"
            :label="opcao.text"
            :valor="opcao.value"
            style="width: 150px"
            class="mb-1"
          />
          <v-chip
            v-if="pontosNaoAtribuidos.find((v) => v.value == opcao.value)"
            label
            color="error"
            small
          >
            Ponto sem instrumento atribuído
          </v-chip>
        </div>
      </accordion-padrao>
      <template v-if="form.variavel">
        <accordion-padrao
          nao-retrair
          :titulo="$t('modulos.calibracao.valores.modal.instrumento_atual')"
        >
          <tabela-padrao
            :colunas="tabela.colunasAtuais"
            :dados="instrumentosAtuais(true)"
            sem-acoes
            sem-paginacao
            :mostrar-checkbox="false"
          >
            <template v-slot:item.faixa="{ item }">
              <div>
                {{ !!item.value ? formatarFaixas([item.value])[0].text : '' }}
              </div>
            </template>
          </tabela-padrao>
        </accordion-padrao>
        <accordion-padrao
          nao-retrair
          :titulo="$t('modulos.calibracao.valores.modal.novo_instrumento')"
        >
          <!-- <div class="d-flex align-end mb-3">
            <input-text
              v-model="filtro.codigo"
              :label="$t('modulos.calibracao.valores.modal.tabela.codigo')"
              class="flex-fill mr-3"
            />
            <input-text
              v-model="filtro.tipoInstrumento"
              :label="
                $t('modulos.calibracao.valores.modal.tabela.tipo_instrumento')
              "
              class="flex-fill mr-3"
            />
            <input-text
              v-model="filtro.setor"
              :label="$t('modulos.calibracao.valores.modal.tabela.setor')"
              class="flex-fill mr-3"
            />
            <botao-padrao
              outlined
              color="secondary"
              @click="filtrar"
            >
              {{ $t('geral.botoes.filtrar') }}
            </botao-padrao>
          </div> -->
          <!-- <tabela-padrao
            v-model="tabela.selecionados"
            :colunas="tabela.colunas"
            :dados="tabela.dados"
            :por-pagina="tabela.porPagina"
            :quantidade-paginas="tabela.quantidadePaginas"
            selecionar-apenas-um
            sem-acoes
            :validacao-selecionar="podeSelecionar"
            @paginar="listarRegistros"
            @input="alterarInstrumentoProvisoriamente"
          >
            <template v-slot:item.faixas="{ item }">
              <div>
                <input-select
                  v-model="item.item.faixaSelecionada"
                  :options="formatarFaixas(item.value)"
                  sem-label
                  class="input-tabela my-1"
                  placeholder="Selecione"
                  style="width: 250px;"
                  @input="alterarInstrumentoProvisoriamente"
                />
              </div>
            </template>
          </tabela-padrao> -->
          <tabela-padrao-prime-vue
            v-model="tabela.selecionados"
            style="min-height: 300px"
            :colunas="tabela.colunas"
            :dados="tabela.dados"
            linha-hover
            :mostrar-seletor="false"
            mostrar-seletor-check-box
            filtros-com-colunas
            filtro-global-focus
            :mostrar-acoes="false"
            :quantidade-itens="tabela.quantidadeItens"
            :por-pagina="tabela.porPagina"
            :quantidade-paginas="tabela.quantidadePaginas"
            :pagina="tabela.paginaAtual"
            :selecionar-apenas-um="false"
            ajustar-altura-linha
            @paginar="listarRegistros"
            @filtrarGlobal="filtrar"
            @filtroGlobal="(v) => (filtroAplicado.filter = v)"
          >
            <template v-slot:seletor="{ slotProps }">
              <div>
                <v-simple-checkbox
                  v-if="podeSelecionar(slotProps.data)"
                  v-model="slotProps.data.isSelected"
                  @input="
                    SelecionarInstrumentoPadrao(
                      slotProps.data,
                      slotProps.data.isSelected
                    )
                  "
                />
              </div>
            </template>
            <template v-slot:faixas="{ slotProps }">
              <div>
                <input-select
                  v-model="slotProps.data.faixaSelecionada"
                  :options="formatarFaixas(slotProps.data.faixas)"
                  sem-label
                  class="input-tabela my-1"
                  placeholder="Selecione"
                  style="width: 250px"
                  @input="alterarInstrumentoProvisoriamente"
                />
              </div>
            </template>
          </tabela-padrao-prime-vue>
        </accordion-padrao>
      </template>
    </div>
  </modal-padrao>
</template>
<script>
import { InstrumentoService, FormulaService } from '@common/services';
// import { TipoColunaConst } from '@const/TipoColunaConst';
import _ from 'lodash';
export default {
  props: {
    valores: {},
    instrumentoId: {},
  },
  data() {
    return {
      valoresClonados: null,
      persistente: false,
      form: {
        variavel: null,
        pontos: [],
      },
      filtro: {
        flagPadrao: true,
      },
      opcoesVariaveis: [],
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'codigo',
            text: this.$t('modulos.calibracao.valores.modal.tabela.codigo'),
            sortable: false,
          },
          {
            value: 'tipoInstrumento.nome',
            text: this.$t(
              'modulos.calibracao.valores.modal.tabela.tipo_instrumento'
            ),
            sortable: false,
          },
          {
            value: 'faixas',
            text: this.$t('componentes.input_instrumento_padrao.tabela.faixas'),
            sortable: false,
          },
          {
            value: 'setor.nome',
            text: this.$t('modulos.calibracao.valores.modal.tabela.setor'),
            sortable: false,
          },
          {
            value: 'validacao',
            text: this.$t('modulos.calibracao.valores.modal.tabela.situacao'),
            sortable: false,
            formatter: (v) => {
              return this.$t(`enums.EnumInstrumentoPadraoValidacao.${v}`);
            },
          },
        ],
        colunasAtuais: [
          {
            value: 'instrumento.codigoTag',
            text: this.$t('modulos.calibracao.valores.modal.tabela.codigo'),
            sortable: false,
          },
          {
            value: 'instrumento.tipoInstrumento.nome',
            text: this.$t(
              'modulos.calibracao.valores.modal.tabela.tipo_instrumento'
            ),
            sortable: false,
          },
          {
            value: 'faixa',
            text: this.$t('componentes.input_instrumento_padrao.tabela.faixas'),
            sortable: false,
          },
          {
            value: 'instrumento.setor.nome',
            text: this.$t('modulos.calibracao.valores.modal.tabela.setor'),
            sortable: false,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
    };
  },
  computed: {
    pontosVazios: function () {
      return this.pontosSemInstrumento
        .filter((value, index, array) => {
          return (
            array.indexOf(
              array.find((el) => {
                return (
                  el.formulaVariavel.id == value.formulaVariavel.id &&
                  el.instrumentoFaixaPontoId == value.instrumentoFaixaPontoId
                );
              })
            ) === index
          );
        })
        .map((el) => {
          el.ponto = this.opcoesPontos.find(
            (v) => v.value == el.instrumentoFaixaPontoId
          );
          return el;
        });
    },
    opcoesVariaveisComputadas: function () {
      return this.opcoesVariaveis.map((el) => {
        el.pontosNaoAtribuidos = this.pontosVazios.filter(
          (pv) => pv.formulaVariavel.id == el.value
        );
        return el;
      });
    },
    pontosNaoAtribuidos: function () {
      if (!this.form.variavel) return [];
      return this.pontosVazios
        .filter((el) => el.formulaVariavel.id == this.form.variavel)
        .map((el) => {
          return el.ponto;
        });
    },
    opcoesPontos: function () {
      if (!this.valoresClonados) return [];
      return this.valoresClonados.linhas
        .filter((value, index, array) => {
          return (
            array.indexOf(
              array.find(
                (el) => el.pontoCalibracao.id == value.pontoCalibracao.id
              )
            ) === index
          );
        })
        .map((el) => {
          return {
            value: el.pontoCalibracao.id,
            text: `${el.pontoCalibracao.valor} - ${el.pontoCalibracao.unidadeMedida.nome}`,
          };
        });
    },
    pontosSemInstrumento: function () {
      if (!this.valoresClonados) return [];
      return this.valoresClonados.instrumentosPadroes.filter(
        (x) => !x.instrumento
      );
    },
  },
  watch: {
    'form.variavel': function () {
      this.tabela.selecionados = [];
      this.listarRegistros(1, 10);
    },
    'form.pontos': function () {
      this.tabela.selecionados = [];
    },
  },
  methods: {
    formatarFaixas: function (faixas) {
      return faixas.map((el) => {
        let nome = el.nome;
        if (!nome) nome = `${el.descricao}`;
        return {
          value: el.id,
          text: `${nome}`,
        };
      });
    },

    SelecionarInstrumentoPadrao: function (item) {
      this.tabela.selecionados = [];
      this.tabela.dados.forEach((el) => {
        if (el.id == item.id) {
          el.isSelected = true;
          this.tabela.selecionados.push(el);
        } else {
          el.isSelected = false;
        }
      });

      this.alterarInstrumentoProvisoriamente()

    },
    podeSelecionar: function (item) {
      return item.validacao == 'Valido' && !!item.faixaSelecionada;
    },
    instrumentosAtuais: function (distinct = false) {
      let instrumentos = this.valoresClonados.instrumentosPadroes.filter(
        (el) => {
          let query = [];
          if (this.form.variavel)
            query.push(el.formulaVariavel.id == this.form.variavel);
          if (this.form.pontos.length)
            query.push(this.form.pontos.includes(el.instrumentoFaixaPontoId));

          return query.every((x) => x) && query.length;
        }
      );
      if (!distinct) return instrumentos;
      return instrumentos.filter((value, index, array) => {
        if (value.instrumento == null) return false;
        return (
          array.indexOf(
            array.find((el) => el.instrumento.id == value.instrumento.id)
          ) === index
        );
      });
    },
    abrirModal: function () {
      this.$refs.modal.abrirModal();
      this.valoresClonados = _.cloneDeep(this.valores);
      this.tabela.selecionados = [];
      this.form.variavel = null;
      this.form.pontos = [];
      this.listarVariaveis();
      this.persistente = false;
    },
    abrirModalPersistente: function () {
      this.abrirModal();
      this.persistente = false;
    },
    fecharModal: function () {
      this.$refs.modal.fecharModal();
    },
    filtrar: function () {
      this.listarRegistros();
    },
    listarVariaveis: function () {
      FormulaService.variaveis(this.valoresClonados.formulaMensurandoId).then(
        (res) => {
          this.opcoesVariaveis = res.data.map((el) => {
            return {
              value: el.id,
              text: `${el.simbolo} - ${el.unidadeMedida.nome}`,
              grandezaFisicaId: el.grandezaFisicaId,
            };
          });
        }
      );
    },
    listarRegistros: function (paginaAtual = 1, porPagina = 10) {
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        sort: '-validacao',
        ...this.filtro,
      };
      parametros.IgnoreInstrumentoId = this.instrumentoId;
      let variavel = this.opcoesVariaveis.find(
        (el) => el.value == this.form.variavel
      );
      if (!variavel) return;
      parametros.FaixaGrandezaFisicaId = variavel.grandezaFisicaId;
      this.$store.dispatch('Layout/iniciarCarregamento');
      InstrumentoService.listarPadrao(parametros)
        .then((res) => {
          this.tabela.dados = res.data.items.map((el) => {
            el.faixaSelecionada =
              el.faixas.length == 1 ? el.faixas[0].id : null;
            return el;
          });
          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    alterarInstrumento: function () {
      this.confirmarSalvar().then(() => {
        this.valores.linhas.forEach((linha) => {
          linha.valores.forEach((valor) => {
            valor.valor = '';
          });
        });
        this.$emit('salvar-tabela', this.valoresClonados);
      });
    },
    alterarInstrumentoProvisoriamente: function () {
      if (this.tabela.selecionados.length == 0) return;
      this.instrumentosAtuais().forEach((registro) => {
        registro.instrumento = this.tabela.selecionados.map((el) => {
          return {
            id: el.id,
            codigoTag: el.codigo,
            tipoInstrumento: el.tipoInstrumento,
            setor: el.setor,
          };
        })[0];

        let faixas = this.tabela.selecionados[0].faixas;
        registro.faixa = faixas.find(
          (el) => el.id == this.tabela.selecionados[0].faixaSelecionada
        );
      });
    },
  },
};
</script>
