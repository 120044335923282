<template>
  <modal-padrao
    ref="modal"
    max-width="900px"
    :titulo="$t('modulos.calibracao.anexos.titulo_modal')"
    :titulo-ok="$t('geral.botoes.salvar')"
    @ok="salvar"
  >
    <div class="row">
      <input-text
        v-model="form.descricao"
        class="col-12"
        :label="$t('modulos.calibracao.anexos.formulario.descricao')"
      />
      <input-file-drag-n-drop
        v-model="form.arquivo"
        class="col-12"
        :multiplos="false"
      />
    </div>
  </modal-padrao>
</template>
<script>
import CalibracaoService from '@common/services/cadastros/CalibracaoService';
import _ from 'lodash';
export default {
  data: function () {
    return {
      form: {
        calibracaoId: '',
        descricao: '',
        arquivo: [],
      },
    };
  },
  methods: {
    abrirModal: function (calibracaoId) {
      this.form = {
        calibracaoId: '',
        descricao: '',
        arquivo: [],
      };
      this.form.calibracaoId = calibracaoId;
      this.$refs.modal.abrirModal();
    },
    salvar: function () {
      let form = _.cloneDeep(this.form);
      if (form.arquivo.length == 0) return;
      form.nomeArquivo = this.form.arquivo[0].nome;
      form.extensaoArquivo = this.form.arquivo[0].extensao;
      form.arquivo = this.form.arquivo[0].base64;

      this.$store.dispatch('Layout/iniciarCarregamento');

      CalibracaoService.salvarAnexo(form.calibracaoId, form)
        .then(() => {
          this.toastSucesso(this.$t(`modulos.calibracao.anexos.anexo_sucesso`));
          this.$refs.modal.fecharModal();
          this.$emit('listar');
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
  },
};
</script>
