var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('div',[(_vm.podeExcluir && _vm.podeEditar)?_c('botao-padrao',{staticClass:"my-2",attrs:{"outlined":"","color":"danger"},on:{"click":_vm.excluirRegistros}},[_c('v-icon',[_vm._v("$mdiTrashCanOutline")]),_vm._v(" "+_vm._s(_vm.$t('geral.botoes.excluir'))+" ")],1):_vm._e()],1),(_vm.podeEditar)?_c('botao-padrao',{staticClass:"mt-3",on:{"click":_vm.anexarArquivo}},[_vm._v(" "+_vm._s(_vm.$t('modulos.calibracao.anexos.botoes.anexar_arquivo'))+" ")]):_vm._e()],1),_c('tabela-padrao',{ref:"tabela",staticClass:"mt-2",attrs:{"dados":_vm.tabela.dados,"colunas":_vm.tabela.colunas,"por-pagina":_vm.tabela.porPagina,"quantidade-paginas":_vm.tabela.quantidadePaginas},on:{"paginar":_vm.listarRegistros},scopedSlots:_vm._u([{key:"item.acoes",fn:function({ item }){return [_c('dropdown-padrao',{attrs:{"text":"","color":"secondary"},scopedSlots:_vm._u([{key:"botao",fn:function(){return [_c('v-icon',[_vm._v(" $dotsVertical ")])]},proxy:true}],null,true)},[_c('div',[_c('botao-padrao',{attrs:{"text":"","color":"secondary"},on:{"click":function($event){return _vm.download(item.item)}}},[_vm._v(" "+_vm._s(_vm.$t('geral.botoes.download'))+" ")])],1)])]}}]),model:{value:(_vm.tabela.selecionados),callback:function ($$v) {_vm.$set(_vm.tabela, "selecionados", $$v)},expression:"tabela.selecionados"}}),_c('v-divider',{staticClass:"my-5"}),_c('div',{staticClass:"d-flex align-center justify-end"},[_c('botao-padrao',{staticClass:"mr-2",attrs:{"outlined":"","color":"secondary"},on:{"click":_vm.cancelar}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" $mdiCancel ")]),_vm._v(" "+_vm._s(_vm.$t('geral.botoes.cancelar'))+" ")],1)],1),_c('modal-anexos',{ref:"modalAnexo",on:{"listar":() => {
        if (this.tabela.dados.length == this.tabela.porPagina) {
          this.tabela.paginaAtual++;
          this.$refs.tabela.alterarPagina(this.tabela.paginaAtual);
        } else {
          this.listarRegistros();
        }
      }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }