<template>
  <div>
    <div
      v-for="(item, index) in requisitosCliente"
      :key="index"
    >
      <accordion-padrao
        :alterar-cor="true"
        cor-azul-texto
        :titulo="
          $t(
            `modulos.participantes.formulario.requisitos.${item.tipoRequisito}`
          )
        "
        class="accordion-padrao mb-5"
      >
        <div
          v-for="(requisitoItem, index) in item.requisitos"
          :key="index"
          class="d-flex"
        >
          <span
            v-if="
              requisitoItem.classificacaoRequisito == 'CampoAdicional' ||
                requisitoItem.classificacaoRequisito == 'TextoLivre'
            "
            class="ml-10"
          >
            {{ requisitoItem.descricao }}
          </span>
          <accordion-padrao
            v-if="requisitoItem.classificacaoRequisito == 'TipoDocumento'"
            cor-azul-texto
            cor-texto="#0038ff"
            alterar-cor
            :titulo="'Documentos Necessários'"
            class="accordion-padrao ml-10"
          >
            <span
              :key="index"
              class="d-flex ml-10"
            >
              {{ requisitoItem.descricao }}
            </span>
          </accordion-padrao>
        </div>
      </accordion-padrao>
    </div>
    <div v-if="requisitosCliente.length === 0">
      Não há requisitos de calibração para este cliente.
    </div>
    <div class="d-flex align-center justify-end">
      <botao-padrao
        outlined
        color="secondary"
        class="mr-2"
        @click="cancelar"
      >
        <v-icon>$mdiCancel</v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    requisitosCliente: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  methods: {
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: this.$route.query?.retorno });
      });
    },
  },
};
</script>
