<template>
  <modal-padrao
    ref="modal-requisitos"
    max-width="80%"
    titulo="Requisitos"
    titulo-cancelar="Fechar"
    :mostra-botao-salvar="false"
  >
    <div
      v-for="(item, index) in requisitosCliente"
      :key="index"
    >
      <accordion-padrao
        :alterar-cor="true"
        cor-azul-texto
        :titulo="$t(`modulos.participantes.formulario.requisitos.${item.tipoRequisito}`)"
        class="accordion-padrao mb-5"
      >
        <div
          v-for="(requisitoItem, index) in item.requisitos"
          :key="index"
          class="d-flex"
        >
          <span
            v-if="requisitoItem.classificacaoRequisito == 'CampoAdicional' ||
              requisitoItem.classificacaoRequisito == 'TextoLivre'"
            class="ml-10"
          >
            {{ requisitoItem.descricao }}
          </span>
          <accordion-padrao
            v-if="requisitoItem.classificacaoRequisito == 'TipoDocumento'"
            cor-azul-texto
            cor-texto="#0038ff"
            alterar-cor
            :titulo="'Documentos Necessários'"
            class="accordion-padrao ml-10"
          >
            <span
              :key="index"
              class="d-flex ml-10"
            >
              {{ requisitoItem.descricao }}
            </span>
          </accordion-padrao>
        </div>
      </accordion-padrao>
    </div>
    <strong class="d-flex mt-12 mb-3">
      {{ $t('modulos.orcamentos.dados_aba_requisitos') }}
    </strong>
  </modal-padrao>
</template>
<script>
export default {
  props: {
    requisitosCliente: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  mounted() {
    if(this.requisitosCliente.length > 0)
      this.abrirModal();
  },
  methods: {
    abrirModal: function () {
      this.$refs['modal-requisitos'].abrirModal();
    },
  },
};
</script>
<style>
.accordion-padrao {
  color: #0d47a1;
}

</style>
